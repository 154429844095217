<template>
	<v-dialog v-model="dialog" persistent max-width="900px">
		<v-card>
			<!-- Header -->
			<v-card-title class="d-flex justify-space-between border-bottom">
				<span class="subtitle-1" v-if="!toEdit">Добавление устройства</span>
				<span class="subtitle-1" v-else>{{ toEdit.name }}</span>
				<v-btn color="dark" @click="closeModal" icon>
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-title>

			<!-- Progress bar -->
			<v-progress-linear
				v-if="loading"
				color="primary"
				height="5"
				indeterminate
			></v-progress-linear>

			<!-- Body -->
			<v-card-text v-else>
				<v-container v-if="!toEdit">
					<v-form ref="form">
						<v-row>
							<!-- Название въезда -->
							<v-col cols="6" sm="6">
								<span class="subtitle-2 font-weight-light">
									Название устройства
								</span>
								<v-text-field
									v-model="data.name"
									class="border-all"
									solo
									dense
									flat
									hide-details
									required
								>
								</v-text-field>
							</v-col>
							<!-- Дом -->
							<v-col cols="6" sm="6">
								<span class="subtitle-2 font-weight-light">
									Адрес
								</span>
								<v-select
									v-model="data.house_ids"
									multiple
									clearable
									class="border-all"
									solo
									dense
									flat
									hide-details
									item-text="apartment_address"
									item-value="id"
									:items="houses"
								></v-select>
							</v-col>
							<!-- Группа -->
							<v-col cols="6" sm="6">
								<span class="subtitle-2 font-weight-light">
									Группа
								</span>
								<v-select
									v-model="group_ids"
									multiple
									clearable
									class="border-all"
									solo
									dense
									flat
									hide-details
									:disabled="!groups.length"
									item-text="name"
									item-value="id"
									:items="groups"
								></v-select>
							</v-col>
							<!-- <v-col cols="6" sm="6">
								<v-checkbox
									v-model="data.active"
									class="ft-14 mt-6"
									label="Активно"
									hide-details
								>
								</v-checkbox>
							</v-col> -->
							<!-- Alert -->
							<v-col cols="12" class="py-0">
								<v-alert
									v-if="alert"
									class="mb-0"
									:type="alert.color"
									:color="alert.color"
									outlined
									dense
								>
									{{ alert.message }}
								</v-alert>
							</v-col>
						</v-row>
					</v-form>
				</v-container>
				<v-container v-else-if="!devices.length">
					<p>Устройств не обнаружено</p>
					<v-btn
							color="primary white--text"
							class="d-block mt-5"
							small
							@click="thirdDialog = true"
						> Добавить устройство в группу
						</v-btn>
				</v-container>
				<v-container v-else>
					<v-form ref="form">
						<v-row v-for="(data, i) in devices" :key="i">
							<!-- Название въезда -->
							<v-col cols="12" sm="3">
								<span class="subtitle-2 font-weight-light">
									Название устройства
								</span>
								<v-text-field
									v-model="data.name"
									class="border-all"
									solo
									dense
									flat
									hide-details
									required
									disabled
								>
								</v-text-field>
							</v-col>
							<v-col cols="12" sm="1">
								<span class="subtitle-2 font-weight-light">
									id
								</span>
								<p class="mt-2">{{ data.id }}</p>
							</v-col>
							<v-col cols="12" sm="3">
								<span class="subtitle-2 font-weight-light">
									Multipass id
								</span>
								<p class="mt-2">{{ data.multipass_device_id }}</p>
							</v-col>
							<!-- Наименование сети -->
							<!-- <v-col cols="12" sm="2">
								<p class="mt-6">{{ data.active ? 'Активен' : 'Неактивен' }}</p>
							</v-col> -->
							<v-col cols="12" sm="4">
								<v-btn
									color="primary white--text"
									class="d-block mt-6"
									small
									@click="secondDialog = true; device_id = data.id"
								> Сгенерировать QR-код
								</v-btn>
							</v-col>
							<v-col cols="12" sm="1">
								<v-btn
									color="red white--text"
									class="d-block mt-6"
									depressed
									small
									@click="showModalConfirm = true; item = data"
								>
								<v-icon small>mdi-close</v-icon>
								</v-btn>
							</v-col>
						</v-row>
						<v-btn
							color="primary white--text"
							class="d-block mt-5"
							small
							@click="thirdDialog = true"
						> Добавить устройство в группу
						</v-btn>
						<v-row>
							<!-- Alert -->
							<v-col cols="12" class="py-0">
								<v-alert
									v-if="alert"
									class="mb-0"
									:type="alert.color"
									:color="alert.color"
									outlined
									dense
								>
									{{ alert.message }}
								</v-alert>
							</v-col>
						</v-row>
					</v-form>
				</v-container>
			</v-card-text>
			<!-- Footer -->
			<v-card-actions class="pt-0 pb-5 px-8 d-flex justify-end">
				<!-- Footer создания -->
				<v-btn
					v-if="!toEdit"
					class="subtitle-2 text-capitalize"
					color="primary"
					depressed
					:disabled="!group_ids.length"
					:loading="isAdding"
					@click="addDevice"
				>
					Добавить
				</v-btn>
			</v-card-actions>
		</v-card>
		<v-dialog v-model="secondDialog" persistent max-width="500px">
			<v-card>
				<!-- Header -->
				<v-card-title class="d-flex justify-space-between border-bottom">
					<span class="subtitle-1">Генерация QR-кода</span>
					<v-btn color="dark" @click="secondDialog = false" icon>
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-card-title>

				<!-- Progress bar -->
				<v-progress-linear
					v-if="secondLoading"
					color="primary"
					height="5"
					indeterminate
				></v-progress-linear>

				<!-- Body -->
				<v-card-text>
					<v-container>
							<v-row>
								<!-- Название въезда -->
								<v-col cols="12">
									<span class="subtitle-2 font-weight-light">
										Выберите тип QR
									</span>
									<Autocomplete
										v-model="qr_type"
										clearable
										dense
										item-text="name"
										item-value="id"
										:items="qrTemplates"
									></Autocomplete>
								</v-col>
							</v-row>
							<v-btn
								color="primary white--text"
								class="d-block mt-4"
								small
								:disabled="!qr_type"
								@click="generate_qr"
							> Сгенерировать и скачать
							</v-btn>
					</v-container>
				</v-card-text>
			</v-card>
		</v-dialog>
		<v-dialog v-model="thirdDialog" persistent max-width="500px">
			<v-card>
				<!-- Header -->
				<v-card-title class="d-flex justify-space-between border-bottom">
					<span class="subtitle-1">Добавление устройства к группе</span>
					<v-btn color="dark" @click="closeThirdDialog" icon>
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-card-title>

				<!-- Progress bar -->
				<v-progress-linear
					v-if="thirdLoading"
					color="primary"
					height="5"
					indeterminate
				></v-progress-linear>

				<!-- Body -->
				<v-card-text>
					<v-container>
						<v-row>
							<v-col cols="12" class="py-0">
								<v-alert
									v-if="secondAlert"
									class="mb-0"
									:type="secondAlert.color"
									:color="secondAlert.color"
									outlined
									dense
								>
									{{ secondAlert.message }}
								</v-alert>
							</v-col>
							<!-- Название въезда -->
							<v-col cols="12">
								<span class="subtitle-2 font-weight-light">
									Введите id устройства
								</span>
								<v-text-field
									v-model="device_id"
									class="border-all"
									solo
									dense
									flat
									hide-details
									required
								>
								</v-text-field>
							</v-col>
						</v-row>
						<v-btn
							color="primary white--text"
							class="d-block mt-4"
							small
							:disabled="!device_id.length"
							@click="addDeviceToGroup"
						> Добавить
						</v-btn>
					</v-container>
				</v-card-text>
			</v-card>
		</v-dialog>
		<!-- Подтверждение удаления -->
		<ModalConfirm
			v-if="showModalConfirm"
			title="Удаление устройства"
			:text="`Удалить ${item.name}?`"
			@close-modal="deleteDevice"
		/>
	</v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

import ModalConfirm from "@/components/app/ModalConfirm";

export default {
	name: "EntriesTableModal",
	components: {
		ModalConfirm,
	},
	props: {
		toEdit: {
			type: Object,
			default: undefined,
		},
	},
	data() {
		return {
			alert: null,
			secondAlert: null,
			group_ids: [],
			data: {
				active: true,
				name: '',
				device_type_id: 1,
				house_ids: []
			},
			groups: [],
			devices: [],
			qrTemplates: [],
			item: {
				name: '',
				id: ''
			},
			qr: '',
			qr_type: '',
			device_id: '',
			secondDialog: false,
			thirdDialog: false,
			dialog: true,
			loading: true,
			secondLoading: false,
			thirdLoading: false,
			isAdding: false,
			isDeleting: false,
			isEditing: false,
			showModalConfirm: false,
		};
	},

	computed: {
		...mapGetters({
			catalog: "catalog/GET_CATALOG",
			houses: "ksk/GET_HOUSES",
		}),
		entryId() {
			return this.catalog.entrances_types.find(e => e.sys_name === "entry").id;
		},
		parkingId() {
			return this.catalog.entrances_types.find(e => e.sys_name === "parking")
				.id;
		},
	},

	watch: {
		alert(val) {
			if (!val) return;
			setTimeout(() => {
				this.alert = null;
			}, 3000);
		},
		'data.house_ids'() {
			setTimeout(() => {
				if(this.data.house_ids.length) {
					this.getGroups()
				}
			}, 700);

		}
	},
	methods: {
		// Валидация
		customValidation() {
			let message;

			if (!this.data.name) {
				message = "Укажите название въезда";
			} else if (!this.data.device_type_id) {
				message = "Укажите тип устройства";
			} else if (!this.data.house_ids ) {
				message = "Укажите адрес";
			}

			if (message) {
				this.alert = {
					color: "error",
					message,
				};
				return false;
			}

			return true;
		},
		async getGroups() {
			try {
				this.loading = true
				this.groups = await this.$api.ksk.load_qr_groups({'house_ids': this.data.house_ids});
				this.loading = false
			} catch (error) {
				console.log('err', error)
			}
		},
		async generate_qr() {
			console.log('generate')
			this.secondLoading = true
			let params = {
				device_id: this.device_id,
				qr_themplate_id: this.qr_type
			}
			const imageData = await this.$api.ksk.qr_generate(params);
			console.log('i', imageData)
      this.saveFile(imageData)
			this.secondLoading = false
			this.secondDialog = false
		},
		saveFile(data) {
			const url = window.URL.createObjectURL(new Blob([data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', 'QR.png');
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			window.URL.revokeObjectURL(url);
		},
		async addDeviceToGroup() {
			try {
				const isDeviceInDevices = this.devices.some(device => device.id == this.device_id);
				if (isDeviceInDevices) {
					return this.secondAlert = {
						color: "info",
						message: "Устройство уже добавлено",
					};
				}
				this.thirdLoading = true
				const device = await this.$api.ksk.load_device(this.device_id);
				console.log('device', device)
				let found = false;
				for (var i = 0; i < device.houses.length; i++) {
					if (device.houses[i].id === this.toEdit.house.id) {
						found = true;
						break;
					}
				}
				if (found) {
					const res = await this.$api.ksk.add_device_to_group({"device_id": this.device_id}, this.toEdit.id);
					this.secondAlert = {
						color: "success",
						message: res.message || "Успешно!",
					};
					this.device_id = ''
					setTimeout(() => {
						this.closeThirdDialog()
					}, 700);
				} else {
					this.secondAlert = {
						color: "info",
						message: "У данного устройства отсутствует доступ к выбранному дому",
					};
					console.log("house.id не найден в массиве houses.");
				}
			} catch (error) {
				console.log('err', error)
				this.secondAlert = {
					color: "error",
					message: error.message.response.data.message || "Произошла ошибка. Повторите еще раз.",
				};
			}	finally {
				this.thirdLoading = false;
			}
		},
		// Добавление въезда
		async addDevice() {
			this.alert = null;

			if (!this.customValidation()) return;

			this.isAdding = true;

			try {
				let res;

				res = await this.$api.ksk.add_device(this.data);
				this.group_ids.map(async (group_id) => {
					return await this.$api.ksk.add_device_to_group({"device_id": res.id}, group_id);
				});

				this.alert = {
					color: "success",
					message: res.success || "Успешно!",
				};
				this.$refs.form.resetValidation();
				this.$emit("update-info");
				this.$emit("close-modal");
			} catch (error) {
				this.alert = {
					color: "error",
					message: error.message || "Произошла ошибка. Повторите еще раз.",
				};
			} finally {
				this.isAdding = false;
			}
		},
		// Удаление устройства
		async deleteDevice(isConfirmed) {
			this.showModalConfirm = false;

			if (!isConfirmed) return;

			try {
				this.loading = true;

				const res = await this.$api.ksk.delete_device(this.item.id);

				this.alert = {
					color: "success",
					message: res.success || "Устройство удалено",
				};

				this.$emit("update-info");
				this.$emit("close-modal");
				this.$emit("update-group", this.toEdit.id);
			} catch (error) {
				this.alert = {
					color: "error",
					message: error.message || "Ошибка",
				};
			} finally {
				this.loading = false;
			}
		},

		// Редактирование въезда
		async editEntry() {
			try {
				this.isEditing = true;

				const res = await this.$api.ksk.edit_entry(this.toEdit.id, this.data);

				this.alert = {
					color: "success",
					message: res.success || "Въезд удален",
				};

				this.$emit("update-info");
			} catch (error) {
				this.alert = {
					color: "error",
					message: error.message || "Ошибка",
				};
			} finally {
				this.isEditing = false;
			}
		},
		closeThirdDialog() {
			this.thirdDialog = false
			this.$emit("close-modal");
			this.$emit("update-group", this.toEdit.id);
		},
		closeModal() {
			this.dialog = false;
			this.$emit("close-modal");
		},
	},

	async created() {
		try {
			this.loading = true;
			this.qrTemplates = await this.$api.ksk.load_qr_templates()
			// if (this.toEdit) {
			// 	this.data = { ...this.toEdit };
			// }
			this.devices = this.toEdit?.devices
		} catch (error) {
			console.error(error);
		} finally {
			this.loading = false;
		}
	},
};
</script>

<style lang="scss" scoped></style>
